import React from "react";
import { Link, FormattedMessage } from "gatsby-plugin-intl";

import Layout from "../components/layout";
import SEO from "../components/seo";

const FAQPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="FAQ" />
      <div>
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10 capitalize">
            <FormattedMessage id="title_faq_page" />
          </h2>
          <div className="mt-6 border-t-2 border-gray-200 pt-6">
            <dl>
              <div className="md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  <FormattedMessage id="faq_page_q_1" />
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    <FormattedMessage id="faq_page_q_1_answer" />
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  <FormattedMessage id="faq_page_q_2" />
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    <FormattedMessage id="faq_page_q_2_answer_1" />{" "}
                    <Link
                      to="/contact-us"
                      className="text-teal-700 font-bold hover:underline"
                    >
                      <FormattedMessage id="faq_page_q_2_answer_link" />
                    </Link>{" "}
                    <FormattedMessage id="faq_page_q_2_answer_2" />
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  <FormattedMessage id="faq_page_q_3" />
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    <FormattedMessage id="faq_page_q_3_answer" />
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  <FormattedMessage id="faq_page_q_4" />
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    <FormattedMessage id="faq_page_q_4_answer_1" />{" "}
                    <span className="font-bold text-gray-700">
                      <FormattedMessage id="faq_page_q_4_answer_2" />
                    </span>
                    !
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  <FormattedMessage id="faq_page_q_5" />
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    <FormattedMessage id="faq_page_q_5_answer_1" />{" "}
                    <Link
                      to="/pricing"
                      className="text-teal-700 font-bold hover:underline"
                    >
                      <FormattedMessage id="faq_page_q_5_answer_link" />
                    </Link>{" "}
                    <FormattedMessage id="faq_page_q_5_answer_2" />
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  <FormattedMessage id="faq_page_q_6" />
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    <FormattedMessage id="faq_page_q_6_answer_1" />{" "}
                    <a
                      href="https://stripe.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold text-green-700 hover:underline"
                    >
                      Stripe
                    </a>{" "}
                    <FormattedMessage id="faq_page_q_6_answer_2" />
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  <FormattedMessage id="faq_page_q_7" />
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    <FormattedMessage id="faq_page_q_7_answer" />
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  <FormattedMessage id="faq_page_q_8" />
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    <FormattedMessage id="faq_page_q_8_answer_1" />{" "}
                    <Link
                      to="/register"
                      className="font-bold text-green-700 hover:underline"
                    >
                      <FormattedMessage id="faq_page_q_8_answer_link" />
                    </Link>{" "}
                    <FormattedMessage id="faq_page_q_8_answer_2" />
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  <FormattedMessage id="faq_page_q_9" />
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    <FormattedMessage id="faq_page_q_9_answer" />
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  <FormattedMessage id="faq_page_q_10" />
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    <FormattedMessage id="faq_page_q_10_answer_1" />{" "}
                    <a
                      href="mailto:support@givfast.com"
                      className="font-bold text-green-700 hover:underline"
                    >
                      support@givfast.com
                    </a>{" "}
                    <FormattedMessage id="faq_page_q_10_answer_2" />
                  </p>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FAQPage;
